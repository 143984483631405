const initTabs = () => {
  const onglets = document.querySelectorAll('.onglets .titre');
  let contents = document.querySelectorAll('.onglet-content');
  let counter = 0;
  onglets.forEach((el) => {
    let indexToUse = counter;
    el.addEventListener('click', function (e) {
      onglets.forEach((el) => el.classList.remove('titre-active'));
      this.classList.add('titre-active');
      contents.forEach((el, index) => {
        el.classList.remove('onglet-content-hidden');
        if (index != indexToUse) {
          el.classList.add('onglet-content-hidden');
        } else {
          initSlider(true)
        }
      });
    });
    counter++;
  });
};
