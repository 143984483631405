const stylizeMegaMenuTabs = () => {
  setTimeout(() => {
    // ajout d'un timeout parce que je pense que le mega menu est fabriqué avec JS, donc il faut attendre pour avoir accès aux élements créé
    const resizeTabs = () => {
      const tabsWrapper = document.querySelector('.ubermenu-tabs-group');
      const tabs = document.querySelectorAll('.ubermenu-tab');

      if (window.innerWidth <= 960) {
        tabs.forEach((el) => (el.style.height = ''));
      }

      const tabsWrapperHeight = parseInt(tabsWrapper.style.minHeight);
      // console.log(tabsWrapper.style.minHeight);
      // console.log(tabsWrapperHeight);
      const oneTabHeight = tabsWrapperHeight / tabs.length;
      tabs.forEach((el) => (el.style.height = `${oneTabHeight}px`));
    };
    resizeTabs();
    window.addEventListener('resize', resizeTabs);
  }, 200);
  const responsiveOpen = document.querySelector('.ubermenu-responsive-toggle');
  const header = document.querySelector('header');
  if (responsiveOpen !== null) {
    jQuery('.ubermenu-responsive-toggle').on(
      'ubermenutoggledopen',
      function (e) {
        //Your code here
        document.body.classList.add('scroll-disabled');
        // header.classList.add('show-orange-thingy');
      }
    );
    jQuery('.ubermenu-responsive-toggle').on(
      'ubermenutoggledclose',
      function (e) {
        //Your code here
        document.body.classList.remove('scroll-disabled');
        // header.classList.remove('show-orange-thingy');
      }
    );
    // responsiveOpen.addEventListener('ubermenutoggledopen', () => {
    //   console.log('open');
    //   document.body.classList.add('scroll-disabled');
    // });
  }
};

const convertComputedHeightToFixed = (element) => {
  if (window.innerWidth > 960) {
    const computedHeight = window
      .getComputedStyle(element, null)
      .getPropertyValue('height');
    // console.log(computedHeight);
    element.style.height = computedHeight;
  }
};

const clearFixedHeight = (element) => {
  element.style.removeProperty('height');
};

const listenToNavMenuOpening = () => {
  setTimeout(() => {
    const mainMenuNav = document.querySelectorAll('.ubermenu-nav > li');
    const mainMenuNavUl = document.querySelectorAll('.ubermenu-nav > li > ul');
    // console.log(mainMenuNav);
    mainMenuNav.forEach((entry1, index1) => {
      entry1.addEventListener('mouseenter', () => {
        setTimeout(() => {
          convertComputedHeightToFixed(mainMenuNavUl[index1]);
          mainMenuNav.forEach((entry2, index2) => {
            if (index2 !== index1) {
              clearFixedHeight(mainMenuNavUl[index2]);
            }
          });
        }, 0);
      });
    });

    window.addEventListener('resize', () => {
      mainMenuNav.forEach((li) =>
        li.classList.remove('ubermenu-in-transition')
      );
    });

    jQuery('.ubermenu-nav > li').on('ubermenuclose', (e) => {
      document.querySelectorAll('.ubermenu-nav > li > ul').forEach(el => {
        clearFixedHeight(el)
      })
    })
  }, 200);
};

listenToNavMenuOpening();
