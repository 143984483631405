/**
 * Represents a book.
 * @constructor
 */

class AdvisaHeader {
  constructor(options) {
    if (Object.keys(options).length === 0) {
      throw new Error('AdvisaHeader: Veuillez renseigner options');
    }

    if (!options.target) {
      throw new Error('AdvisaHeader: La propriété target est obligatoire.');
    }
    if (typeof options.target !== 'string') {
      throw new Error(
        'AdvisaHeader: La propriété target doit être une chaîne de caractères.'
      );
    }
    this.target = document.querySelector(options.target);

    if (this.target === null) {
      throw new Error(
        `AdvisaHeader: Aucun élément trouvé pour option target.: ${options.target}`
      );
    }

    this.offsetScroll = parseInt(options.offsetScroll) || 0;
    this.translateHeight = options.translateHeight || '-100%';
    this.scrollUpClass = options.scrollUpClass || 'scroll-up';
    this.scrollDownClass = options.scrollDownClass || 'scroll-down';
    this.transitionDuration = options.transitionDuration || '0.2s';

    this.trackScrollClasses();

    if (
      window.meh === null ||
      window.meh === undefined ||
      window.meh.length === 0
    ) {
      window.meh = [];
      window.meh.push(this);
    } else {
      window.meh.push(this);
    }
  }

  trackScrollClasses() {
    let startingPosition = window.scrollY;
    let lastScrollDirection;
    const uberMenu = document.querySelectorAll('.ubermenu-main li');
    // console.log(startingPosition);
    if (startingPosition > 0) {
      this.target.classList.add(this.scrollDownClass);
      this.target.style.transform = `translateY(${this.translateHeight})`;
    }
    this.target.style.transitionDuration = this.transitionDuration;
    window.addEventListener('scroll', () => {
      if (
        window.scrollY > startingPosition &&
        lastScrollDirection !== 'bottom'
      ) {
        // console.log('class scroll down');
        startingPosition = window.scrollY;
        this.target.classList.add(this.scrollDownClass);
        this.target.classList.remove(this.scrollUpClass);
        this.target.style.transform = `translateY(${this.translateHeight})`;
        // console.log(uberMenu);
        lastScrollDirection = 'bottom';
      } else if (
        window.scrollY < startingPosition &&
        lastScrollDirection !== 'top'
      ) {
        // console.log('class scroll up')
        startingPosition = window.scrollY;
        this.target.classList.add(this.scrollUpClass);
        this.target.classList.remove(this.scrollDownClass);
        this.target.style.transform = `translateY(0px)`;
        lastScrollDirection = 'top';
      }
      if (window.scrollY === 0) {
        this.target.classList.remove(this.scrollUpClass);
        this.target.classList.remove(this.scrollDownClass);
      }
      startingPosition = window.scrollY;
    });
  }
}

// export default AdvisaHeader;

// decaler le body
