// const initFloatingLabel = (elem, event) => {
//   elem.parentNode.parentNode.querySelector('label').classList.add('filled');
//   if (event.target.value === '') {
//     elem.parentNode.parentNode.querySelector('label').classList.remove('filled');
//   }
// }

const init_gform_values = () => {
  const gforms_input = document.querySelectorAll('.gform_wrapper input[type="text"], .gform_wrapper input[type="email"]');
  if (gforms_input !== null) {
    Array.from(gforms_input).forEach((gform) => {
      if (gform.value !== '') {
        gform.parentNode.parentNode.querySelector('label').classList.add('filled');
      }
      gform.addEventListener('keyup', (event) => {
          event.target.setAttribute('value', event.target.value);
          event.target.parentNode.parentNode.querySelector('label').classList.add('filled');
          // if (event.target.value === '') {
          //   event.target.parentNode.parentNode.querySelector('label').classList.remove('filled');
          // }
      });

      gform.addEventListener('focus', (e) => {
        e.target.parentNode.parentNode.querySelector('label').classList.add('filled');
      });

      gform.addEventListener('blur', (e) => {
        if (e.target.value === '') {
          e.target.parentNode.parentNode.querySelector('label').classList.remove('filled');
        }
      });
    });
  }

  


  // const gforms_select = document.querySelectorAll('.gform_wrapper select');
  // if (gforms_select !== null) {
  //   Array.from(gforms_select).forEach(function(select){
  //     select.addEventListener('change', function() {
  //       this.classList.add('select-updated');
  //     });
  //     if (select.options[select.selectedIndex].value !== '' && select.options[select.selectedIndex].selected) {
  //       select.classList.add('select-updated');
  //     }
  //   });
  // }

  // const gforms_readonly = document.querySelectorAll('.gform_wrapper .read-only input');
  // if (gforms_readonly !== null) {
  //   Array.from(gforms_readonly).forEach(function(input){
  //     input.setAttribute('readonly', '');
  //   });
  // }
}

// function init_upload_field() {
//   const uploads = document.querySelectorAll('.gform_wrapper .ginput_container_fileupload input[type="file"]');

//   if (uploads !== null) {
//     Array.from(uploads).forEach(function(upload){
//       let label = upload.parentNode.parentNode.querySelector('.gfield_label');
//       label = label.innerHTML.replace('<span class="gfield_required">*</span>',' *');
//       upload.parentNode.setAttribute('data-text', label)

//       upload.addEventListener('change', function() {

//         this.parentNode.setAttribute("data-text", this.value.replace(/.*(\/|\\)/, '') );
//       })
//     });
//   }
// }
const gformWrapper = document.querySelector('.gform_wrapper');
if (gformWrapper !== null) {
  jQuery(document).on('gform_post_render', function(event, form_id, current_page){
    // $cform = jQuery('.gform_wrapper').clone().wrap('<p/>').parent().html();
    init_gform_values();
  });
} 