const initGradientNews = () => {
  const recalcGradientNews = () => {
    const newsTitle = document.querySelector(
      '.flex-bloc-actualites .title-wrapper'
    );
    const newsList = document.querySelector('.flex-bloc-actualites .actu-list');
    if (newsTitle !== null && newsList !== null) {
      const newsGradient = document.querySelector('.actualites-gradient');
      var newsTitleH = parseInt(
        window.getComputedStyle(newsTitle, null).getPropertyValue('height')
      );
      var newsListH = parseInt(
        window.getComputedStyle(newsList, null).getPropertyValue('height')
      );
  
      newsGradient.style.bottom = `${newsListH - newsTitleH}px`;
      newsGradient.style.opacity = '1';
    }
  }
  recalcGradientNews();
  window.addEventListener('resize', () => {
    recalcGradientNews;
  });

};

