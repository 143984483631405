let mySwiper;
const initSlider = (needUpdate) => {
  if ( needUpdate ) {
      // clean up old instances and inline styles when available
    if ( mySwiper !== undefined ) {
      mySwiper.destroy( true, true );
      return enableSwiper();
    }
  } else {
    return enableSwiper();
  }
};


const enableSwiper = function() {
  const myCustomSlider = document.querySelector('.onglet-content:not(.onglet-content-hidden) .slider');
  mySwiper = new Swiper(myCustomSlider, {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    watchOverflow: true, // When enabled Swiper will be disabled and hide navigation buttons on case there are not enough slides for sliding.
    navigation: {
      nextEl: '.next',
      prevEl: '.prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    Observer: true, // ​​automatically modify the swiper when modifying the swiper itself or child elements
  });
};


let fullWidthSlider = document.querySelector('.full-width-slider');
const initFWSlider = () => {
  if (fullWidthSlider) {
    const swiper = new Swiper(fullWidthSlider, {
      slidesPerView: 'auto',
      spaceBetween: 70,
      centeredSlides: true,
      navigation: {
        nextEl: '.full-width-slider-next',
        prevEl: '.full-width-slider-previous',
      },
      Observer: true,
      loop: true,
      pagination: {
        el: '.full-width-slider-pagination',
        type: 'bullets',
        clickable: true,
      },
    })
  }
}