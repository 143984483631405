const initializeOffresOnglet = () => {
  const mainButtons = document.querySelectorAll('.offres-onglets-titre');
  const contents = document.querySelectorAll('.offres-content-singular');
  const images = document.querySelectorAll('.offres-image-wrapper');
  if (mainButtons && contents && images) {
    mainButtons.forEach((el) => {
      el.addEventListener('click', function () {
        if (!this.classList.contains('active')) {
          const indexToShow = Array.prototype.indexOf.call(mainButtons, this); // NodeList n'a pas de méthode indexOf, donc on le passe comme un élément itérable à la méthode du Array.prototype qui gére ça comme un pro 😎
  
          // les 3 prochaines boucles vont sélectionner l'onglet qui correspond au bouton cliqué
          mainButtons.forEach((button) => {
            if (button != this) {
              button.classList.remove('active');
            } else {
              button.classList.add('active');
            }
          });
          contents.forEach((content, index) => {
            if (index != indexToShow) {
              content.classList.remove('active');
            } else {
              content.classList.add('active');
            }
          });
          images.forEach((image, index) => {
            if (index != indexToShow) {
              image.classList.remove('active');
            } else {
              image.classList.add('active');
            }
          });
        }
      });
    });
  } else {
   const url = window.location.hostname;
   console.log(url);
   if (url.indexOf('localhost') !== -1 || url.indexOf('validation') !== -1) {
     console.log("pas de bloc options d'offres sur cette page");
   } 
  }
};

initializeOffresOnglet();
