const initContactEmailListener = () => {
  const emailOrigin = document.querySelector('.email-origin select');
  const emailDest = document.querySelector('.email-dest input');
  if (emailOrigin && emailDest) {
    emailOrigin.addEventListener('change', () => {
      emailDest.value = emailOrigin.value;
    })
  }
}

