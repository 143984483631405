const substituteSearchButton = () => {
  const fakeButton = document.querySelector('.searchform img');
  const form = document.querySelector('.searchform form');
  let hasBeenClicked = false;
  fakeButton.addEventListener('click', (e) => {
    if (hasBeenClicked) {
      // console.log('triggered after changing bool');
      form.submit();
    }
    if (window.innerWidth < 992) {
      // console.log('clicked but not triggered');
      hasBeenClicked = true;
    } else {
      // console.log('triggered in wide screen');
      form.submit();
    }
  });
};
