// let prevScrollpos = window.pageYOffset;
// window.addEventListener('scroll', () => {
//   const currentScrollPos = window.pageYOffset;
//   if (currentScrollPos < window.innerHeight) {
//     document.body.classList.remove('scrolled');
//   } else {
//     document.body.classList.add('scrolled');
//   }
// });

const toggleMenu = () => {
  const menuIcon = document.querySelector('.toggle-nav');
  const menuModal = document.querySelector('.menu-modal');
  if (menuIcon !== null) {
    menuIcon.addEventListener('click', () => {
      menuIcon.classList.toggle('open');
      menuModal.classList.toggle('active');
      document.body.classList.toggle('showing-menu-modal');
    });
  }
}

// const toogleHoverSubMenu = () => {
//   const itemHasSubMenu = document.querySelectorAll('.primary-menu-wrapper .primary-menu>li.menu-item-has-children');
//   const header = document.querySelector('#site-header');

//   if (itemHasSubMenu.length !== null) {
//     Array.from(itemHasSubMenu).forEach(item => {
//       item.addEventListener('mouseenter', () => {
//         header.classList.add('submenu--open');
//       });
//       item.addEventListener('mouseleave', () => {
//         setTimeout(() => {
//           header.classList.remove('submenu--open');
//         }, 300);
//       });
//     });
//   }
// };


const isIOS = () => {
  const iOS =
    navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0)
      ? true
      : false;
  return iOS;
};

let menu = undefined;
if (!isIOS()) {
  menu = new AdvisaHeader({
    target: '#site-header',
  });
}